import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// data
import { websolutions as clientsList } from '@data/clients';

// components
import Layout from '@src/layouts';
import Link from '@components/atoms/Link';
import ButtonPrimary from '@components/atoms/buttons/ButtonPrimary';
import SectionTitle from '@components/atoms/SectionTitle';
import ContactBox from '@components/molecules/ContactBox';
import FormMessage from '@components/molecules/FormMessage';
import HeroPage from '@components/molecules/heros/HeroPage';
import ItemBullet from '@components/molecules/items/ItemBullet';
import ListCards from '@components/organisms/ListCards';
import ListClients from '@components/organisms/ListClients';
import ListRelatedProjects from '@components/organisms/ListRelatedProjects';

// assets
import imgSolutions from '@assets/images/solutions.svg';
import iconBullet1 from '@assets/images/bullet_1.svg';
import iconBullet2 from '@assets/images/bullet_2.svg';
import iconBullet3 from '@assets/images/bullet_3.svg';
import imgDigitalProduct from '@assets/images/services/ico-web-digital-product.svg';
import imgUserResearch from '@assets/images/services/ico-web-user-research-proto.svg';
import imgMarketingWebsite from '@assets/images/services/ico-web-marketing-website.svg';
import imgExperienceWebsite from '@assets/images/services/ico-web-experience-website.svg';
import imgProgressiveWebApp from '@assets/images/services/ico-web-mobile-web-app.svg';
import imgEcommerce from '@assets/images/services/ico-web-e-commerce.svg';
import imgResearchAndDevelopment from '@assets/images/services/ico-web-research-and-dev.svg';
import gifFwt from '@assets/images/projects/thumbs/SQUARE-FWT.gif';

// --------------------------------
// #region data
// --------------------------------

const language = 'en';

const seo = {
	title: 'Swiss web agency – Lausanne, Geneva',
	description:
		'Swiss web agency in Lausanne and Geneva, creation and development of websites, e-commerce, mobile applications to increase your impact.',
};

const pageClass = 'page-websolutions';

const footerRelatedLinks = [
	{ title: 'Branding', url: '/en/swiss-branding-agency/' },
	{ title: 'Strategy', url: '/en/digital-strategy-agency/' },
];

const stepsList = [
	{
		image: iconBullet1,
		title: 'Lay your idea on the table',
		text: [
			`Every great adventure begins with a first encounter. You tell us your reality, and together we'll clarify your objectives and draft a strategic game plan to get you there.`,
		],
	},
	{
		image: iconBullet2,
		title: 'We bring solutions',
		text: [
			`We get it: your project is one-of-a-kind with its own quirks and peculiarities. That's why we handpick a talented team capable of developing a response to your challenges which is on point.`,
			<>
				Collaborative Workshops
				<br />
				Digital Development
				<br />
				KPI Measurement
			</>,
		],
	},
	{
		image: iconBullet3,
		title: 'Celebrate your success',
		text: [
			`This is the part where we hone in on the results, analyze the impact and further optimize your potential. Then it's time to join forces to celebrate the success of your project.`,
		],
	},
];

const cardsList = [
	{
		image: imgDigitalProduct,
		title: 'Service Design & Digital Product',
		alt: 'Service Design & Digital Product',
		text: 'We craft products and services that delight your users through a creative and collaborative process, focused on understanding their needs and expectations.',
		// link: { title: 'Lire plus', url: '/todo' },
	},
	{
		image: imgUserResearch,
		title: 'User Research & Prototyping',
		alt: 'User Research & Prototyping',
		text: 'The behaviour of your users is the foundation for directing decisions. We carry out research, prototyping and testing to validate your approach.',
	},
	{
		image: imgMarketingWebsite,
		title: 'Marketing Website',
		alt: 'Marketing Website',
		text: 'Creation of your website with a strong focus on impact generation (KPIs) and in line with your brand personality.',
		// link: { title: 'Lire plus', url: '/todo' },
	},
	{
		image: imgExperienceWebsite,
		title: 'Experience Website',
		alt: 'Experience Website',
		text: 'Your website must differentiate your brand. We create "haute couture" websites that enhance the digital experience with an unwavering dedication to quality. Our international awards are our pledge!',
		// link: { title: 'Lire plus', url: '/todo' },
	},
	{
		image: imgProgressiveWebApp,
		title: 'Web and Mobile Applications',
		alt: 'Web and Mobile Applications',
		text: (
			<>
				Design and development of{' '}
				<dfn>
					<abbr title="Progressive Web App">PWA</abbr>
				</dfn>{' '}
				s and native apps capitalising on the latest technologies,
				allowing you to engage your users and make your services
				accessible in an unparalleled way.
			</>
		),
	},
	{
		image: imgEcommerce,
		title: 'E-Commerce',
		alt: 'E-Commerce',
		text: 'Conceptualisation, development and complete digital support to generate and optimise your online sales.',
	},
	{
		image: imgResearchAndDevelopment,
		title: 'Research & Development',
		alt: 'Research & Development',
		text: (
			<>
				There is what we have done, and there is what we can do. Our
				team model is specifically designed to maximize exploration and
				innovation.{' '}
				{/* <span
					style={{ fontSize: '.7em', lineHeight: 1.2, opacity: 0.7 }}
				>
					note: à venir, une page de détails valorisant ce qu'on a
					fait (filtres insta, video incrustée, vidéo interactive,…)
					mais aussi notre capacité à relever des challenges
				</span> */}
			</>
		),
		// link: { title: 'Lire plus', url: '/todo' },
	},
];

// --------------------------------
// #endregion
// --------------------------------

const WebSolutionsPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				projectImages: allFile(
					filter: {
						relativePath: {
							in: ["projects/thumbs/PORTRAIT-ECA.jpg"]
						}
					}
				) {
					edges {
						node {
							relativePath
							name
							childImageSharp {
								id
								fluid(maxWidth: 620) {
									aspectRatio
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}
			}
		`
	);

	data.getImageAttributes = function (dataKey, relativePath) {
		const imageAttributes = this[dataKey]['edges'].find(
			({ node }) => node.relativePath === relativePath
		).node.childImageSharp;

		return imageAttributes;
	};

	const projectsList = [
		{
			link: '/en/projects/eca-vaud/',
			image: data.getImageAttributes(
				'projectImages',
				'projects/thumbs/PORTRAIT-ECA.jpg'
			),
			title: 'ECA Vaud',
			subtitle: 'UI concept – Design – Web development - Workshops',
		},
		{
			link: '/en/projects/fwt-freeride-world-tour/',
			image: {
				type: 'gif',
				fluid: [
					{
						src: gifFwt,
					},
				],
				// the aspectRatio is automatically set for jpg images coming from `useStaticQuery`, but for GIFs we need to specify it to help with size calculation
				aspectRatio: 496 / 496, // width / height
			},
			title: 'Freeride World Tour Website',
			subtitle: 'UI concept - Design - Web development - Workshops',
			linkedProjects: [
				{
					link: '/en/projects/fwt-freeride-world-tour-branding/',
					title: 'Discover FWT rebranding',
				},
			],
		},
	];

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			location={props.location}
			translations={[{ uri: '/fr/agence-web-suisse/', lang: 'fr' }]}
			seo={seo}
		>
			<HeroPage
				image={imgSolutions}
				alt="Swiss web agency – Lausanne, Geneva"
				title={'Web Solutions'}
				subtitle={'Swiss web agency – Lausanne, Geneva'}
			/>

			<section
				className="grid text-content text-center"
				aria-label="Introduction"
				data-animation-page
			>
				<h2 className="col-big">
					Establish your brand credibility
					<br /> Improve user experience <br /> Convert leads into
					customers
				</h2>
				<p className="p-highlight col-big">
					Tailor-made apps and websites
				</p>
			</section>

			<section className="grid grid-content" aria-label="Description">
				<div className="box text-content" data-animation-page>
					<h2>Let the Web help you smash your business goals</h2>
					<h3>Do you want to:</h3>
					<ul className="list-two-columns list-default list-brand">
						<li>
							<p>
								Leverage your brand positioning with powerful
								digital tools?
							</p>
						</li>
						<li>
							<p>
								Establish the credibility of your brand with
								your target audience?
							</p>
						</li>
						<li>
							<p>
								Build loyalty and increase conversions by
								offering an unprecedented digital experience?
							</p>
						</li>
						<li>
							<p>
								Activate new revenue streams in fast-moving
								markets?
							</p>
						</li>
						<li>
							<p>Collect and qualify targeted leads?</p>
						</li>
					</ul>
					<ButtonPrimary
						className="element-left"
						href="#message"
						text="Our inbox is always open"
					/>
				</div>
			</section>

			<section className="grid" aria-label="Clients">
				<div
					className="box text-center text-content"
					data-animation-page
				>
					<p className="uptitle">Who’s put their trust in us?</p>
					<ListClients clientsList={clientsList} />
				</div>
			</section>

			<section
				className="grid grid-content text-content text-center"
				aria-label="Projects"
				data-animation-page
			>
				<p className="uptitle">Web & Digital Projects</p>
				<h2>Two success stories</h2>
				<ListRelatedProjects
					className={['element-spacing', 'col-big']}
					projectsList={projectsList}
				/>
			</section>

			<section aria-label="Process">
				<SectionTitle title="Three steps" />
				<div className="grid">
					<div className="box text-content" data-animation-page>
						<p className="uptitle">Three simple steps to success</p>
						<h2>Here's what to do:</h2>
						<div className="list-three-columns">
							{stepsList.map((step, index) => (
								<ItemBullet
									key={`step-${index}`}
									image={step.image}
									title={step.title}
									text={step.text}
								/>
							))}
						</div>
					</div>
				</div>
			</section>

			<section className="grid" aria-label="Contact">
				<ContactBox
					title={
						<>
							Tell us about
							<br />
							your web challenge
							{/* TODO: Tell us about your web challenge: we're all ears. */}
						</>
					}
					link={{ title: 'Email us', url: '#message' }}
					text={
						<>
							Or call:{' '}
							<Link href="tel:+41216521818">
								+41 21 652 18 18
							</Link>
						</>
					}
				/>
			</section>

			<section
				className="grid grid-content text-content text-center"
				aria-label="Services"
				data-animation-page
			>
				<p className="uptitle">Areas of expertise</p>
				<h2>Web & digital services</h2>
				<ListCards modifiers="zigzag" cardsList={cardsList} />
			</section>

			<section aria-label="Commitment">
				<SectionTitle title="Our commitment" />

				<div className="grid">
					<div className="text-content box" data-animation-page>
						<p className="uptitle">Our six-point commitment</p>
						<h2>Our commitment is total: in words and in deed</h2>
						<ul className="list-two-columns">
							<li>
								<h4>Size isn’t everything</h4>
								<p>
									Your project matters to us whether you're a
									major player or a fresh-faced start-up. We
									want to help you bring it to life and be
									part of your success. That’s what drives us.
								</p>
							</li>
							<li>
								<h4>Trendy(ish)</h4>
								<p>
									Drawing inspiration from the latest trends
									makes us tick. But you won't find us letting
									these trends cloud our judgement. Instead,
									it's our quest for sustainability,
									performance and originality that guides our
									choices.
								</p>
							</li>
							<li>
								<h4>Our word is our word</h4>
								<p>
									You’ll never catch us making empty promises.
									Are we salespeople? Sure. But we're highly
									specialized in what we sell. We’re frank,
									committed and, most of all, sincere. When we
									give you our word, you can go to the bank
									with it.
								</p>
							</li>
							<li>
								<h4>We're not divas</h4>
								<p>
									We'll bring solid ideas on how to proceed
									efficiently, but "our way or the highway" is
									not in our vocabulary. Chip in with your
									ideas and we won't be offended. Promise.
									Instead, we'll consider your perspective to
									come up with a game plan to move forward
									with your best interests at heart.
								</p>
							</li>
							<li>
								<h4>Digital culture, but also…</h4>
								<p>
									There's no denying it - we're children of
									the digital age, who are getting our hands
									dirty playing our part in redefining its
									norms. Yet, our favorite tool is our brain
									and we're not scared to go the old-fashioned
									paper route when needed.
								</p>
							</li>
							<li>
								<h4>Better together</h4>
								<p>
									Communication is the beating heart of
									successful relationships. That’s why
									face-to-face meetings are our jam. Getting
									together for strategy sessions helps us
									build a common understanding of your
									challenges, which results in better
									projects.
								</p>
							</li>
						</ul>
					</div>
				</div>
			</section>

			<section className="grid" aria-label="Send Message" id="message">
				<FormMessage
					title="Need a hand?"
					text="Email us at"
					contact="hello@superhuit.ch"
				/>
			</section>
		</Layout>
	);
};

export default WebSolutionsPage;
